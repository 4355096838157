.container {
    display: flex; /* Make the parent container a flex container */
    justify-content: space-between; /* Add space between columns */
}

.left-column {
    width: 30%;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin: 20px;
    margin-right: 10px;
}

.right-column {
    flex: 1; /* This allows the right column to take the remaining space */
    padding: 20px;
    margin: 20px;
    margin-left: 10px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

#upload-btn {
    display: block;
    width: 94%;
    padding: 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

#upload-btn:hover {
    background-color: #0056b3;
}

#log-output {
    width: 100%;
    height: 50%;
    margin-top: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    background-color: #f9f9f9;
    font-size: 14px;
    color: #333;
    overflow-y: auto;
    box-sizing: border-box;
}

#right-div {
    width: 100%;
    height: 100%;
    border: 1px dashed #ccc;
    background-color: #f0f4f8;
    border-radius: 8px;
}

.confirm-btn {
    margin-top: 10px;
    width: 100%;
    height: 30px;
}


.select-file{

}